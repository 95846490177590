import React from "react"
import { Link } from "gatsby"

import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "@/components/layout"

import BackgroundImage from "gatsby-background-image"
import Services from "@/components/services"
import SEO from "@/components/seo"

const SecondPage = () => {
  const data = useStaticQuery(graphql`
    query ServiceQuery {
      service: file(relativePath: { eq: "tahtaane-malek-services.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      arrowRight: file(relativePath: { eq: "arrow-right.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Page two" />
      <div
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: 250,
          backgroundColor: "#404543",
        }}
      ></div>

      <div className="col-lg-12">
        <div className="container mt-5 pt-5">
          <div className="row">
            <div className="col-lg-6 pr-5">
              <h1 style={{ color: "#33af5b", fontSize: 32 }}>
                Offre de service Entreprise Tahtane Malek
              </h1>
              <h2 className="mb-5" style={{ color: "#8bc63e" }}>
                Aménagement d'espaces verts
              </h2>

              <p>
                <strong>L'Entreprise TAHTANE MALEK</strong> vous propose des
                prestations et services de qualités à des prix compétitifs, elle
                vous assure tous les travaux de:
              </p>
              <ul style={{ listStyle: "none" }} className="p-0 mt-3">
                <li className="d-flex flex-row align-items-center">
                  <Img
                    style={{ width: 13, height: 13 }}
                    fluid={data.arrowRight.childImageSharp.fluid}
                  />
                  <span className="ml-3">
                    Conceptions et réalisations de Terrasses et Cours pour les
                    particuliers et professionnels;
                  </span>
                </li>
                <li className="d-flex flex-row align-items-center">
                  <Img
                    style={{ width: 13, height: 13 }}
                    fluid={data.arrowRight.childImageSharp.fluid}
                  />
                  <span className="ml-3">
                    Conceptions et réalisations de Jardins personnalisés et
                    Aménagements de Paysages;
                  </span>
                </li>
                <li className="d-flex flex-row align-items-center">
                  <Img
                    style={{ width: 13, height: 13 }}
                    fluid={data.arrowRight.childImageSharp.fluid}
                  />
                  <span className="ml-3">
                    Conceptions et réalisations de Pergolas;
                  </span>
                </li>
                <li className="d-flex flex-row align-items-center">
                  <Img
                    style={{ width: 13, height: 13 }}
                    fluid={data.arrowRight.childImageSharp.fluid}
                  />
                  <span className="ml-3">
                    Tonte de pelouse Plantations - Parterres - Pulvérisation des
                    allées.
                  </span>
                </li>
                <li className="d-flex flex-row align-items-center">
                  <Img
                    style={{ width: 13, height: 13 }}
                    fluid={data.arrowRight.childImageSharp.fluid}
                  />
                  <span className="ml-3">
                    Fourniture et Pose de Gazon Naturel, Arbres et Plantes
                    Vertes;
                  </span>
                </li>
                <li className="d-flex flex-row align-items-center">
                  <Img
                    style={{ width: 13, height: 13 }}
                    fluid={data.arrowRight.childImageSharp.fluid}
                  />
                  <span className="ml-3">
                    Prestation de Tonte de Pelouse et Pulvérisations des Allées;
                  </span>{" "}
                </li>
                <li className="d-flex flex-row align-items-center">
                  <Img
                    style={{ width: 13, height: 13 }}
                    fluid={data.arrowRight.childImageSharp.fluid}
                  />
                  <span className="ml-3">
                    Prestations de Tailles de Haies, d'Arbustes, d'Arbres
                    Fruitiers et d'Ornement et Elagages et Abattages d'Arbres;
                  </span>{" "}
                </li>
                <li className="d-flex flex-row align-items-center">
                  <Img
                    style={{ width: 13, height: 13 }}
                    fluid={data.arrowRight.childImageSharp.fluid}
                  />
                  <span className="ml-3">
                    Prestations d'Entretien des Arbustes et des Espaces Verts;
                  </span>
                </li>{" "}
                <li className="d-flex flex-row align-items-center">
                  <Img
                    style={{ width: 13, height: 13 }}
                    fluid={data.arrowRight.childImageSharp.fluid}
                  />
                  <span className="ml-3">
                    Conception de Paysages - Jardins Cosy et d'Allées de
                    Jardins;
                  </span>
                </li>{" "}
                <li className="d-flex flex-row align-items-center">
                  <Img
                    style={{ width: 13, height: 13 }}
                    fluid={data.arrowRight.childImageSharp.fluid}
                  />
                  <span className="ml-3">
                    Conceptions et Entretiens des Etangs et Pièces d'eau;
                  </span>
                </li>{" "}
                <li className="d-flex flex-row align-items-center">
                  <Img
                    style={{ width: 13, height: 13 }}
                    fluid={data.arrowRight.childImageSharp.fluid}
                  />
                  <span className="ml-3">Assistance et Conseil.</span>
                </li>
              </ul>
            </div>
            <div
              className="col-lg-6 d-lg-block d-none p-0"
              style={{ position: "relative" }}
            >
              <BackgroundImage
                style={{ height: "100%", width: "100%" }}
                Tag="section"
                fluid={data.service.childImageSharp.fluid}
              ></BackgroundImage>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  background:
                    "linear-gradient(180deg, #404543 10%, transparent)",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-5 p-0 pb-5">
        <Services />
      </div>
    </Layout>
  )
}

export default SecondPage
